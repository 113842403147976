@use '@angular/material' as mat;

/* You can add global styles to this file, and also import other style files */

:root {

  --main-color: #18191b;
  --alternate-color: #ff4403;

}

.blank-container {

  background-image: url(/assets/images/background/login-register.jpg);

}

// http://mcg.mbitson.com/#!?mcgpalette0=%233f51b5

@import '@angular/material/theming';

$primary-palette: (
  50: #ffe9e1,
  100: #ffc7b3,
  200: #ffa281,
  300: #ff7c4f,
  400: #ff6029,
  500: #ff4403,
  600: #ff3e03,
  700: #ff3502,
  800: #ff2d02,
  900: #ff1f01,
  A100: #ffffff,
  A200: #fff3f2,
  A400: #ffc4bf,
  A700: #ffaca6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$secondary-palette: (
  50: #e3e3e4,
  100: #bababb,
  200: #8c8c8d,
  300: #5d5e5f,
  400: #3b3c3d,
  500: #18191b,
  600: #151618,
  700: #111214,
  800: #0e0e10,
  900: #080808,
  A100: #ff5050,
  A200: #ff1d1d,
  A400: #e90000,
  A700: #cf0000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$primary: mat.define-palette($primary-palette, 600);
$accent: mat.define-palette($secondary-palette, 500);
$warn: mat.define-palette(mat.$red-palette, 500);
$theme: mat.define-light-theme($primary, $accent, $warn);

@include mat.all-legacy-component-themes($theme);
